import React from "react";

const GenerateReportPage = () => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");

  const toDateTime = (seconds) => {
    let t = new Date(1970, 0, 1);

    t.setSeconds(Number(String(seconds).slice(0, 7)) * 1000);
    
    t.setHours(t.getHours() + 8);
    
    return t.toString();
  }

  const generateReport = async () => {
    const query = `
      query {
        attendees(username: "${username}", password: "${password}") {
          title
          firstName
          lastName
          salutation
          affiliation
          email
          phoneNumber
          occupation
          createdAt
          checkedIn
        }
      }
    `;

    setAlertMessage("Generating Report");

    fetch(process.env.SYMPOSIUM_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query: query }),
    })
    .then((res) => {  
      return res.json();
    })
    .then((res) => {
      if (res.data.attendees) {
        const rows = res.data.attendees.map((attendee) => `${attendee.firstName}, ${attendee.lastName}, ${attendee.title}, ${attendee.salutation}, ${attendee.affiliation}, ${attendee.email}, ${attendee.phoneNumber}, ${attendee.occupation}, ${toDateTime(attendee.createdAt)}, ${attendee.checkedIn ? 'Checked In' : 'Registered'}`).join("\n");

        const csvFileHeader = `First Name, Last Name, Title, Salutation, Affiliation, Email, Phone Number, Occupation, Registration Time, Checked In\n`;

        const link = document.createElement("a");

        link.setAttribute("href", encodeURI("data:text/csv;charset=utf-8," + csvFileHeader + rows));
        
        link.setAttribute("download", `attendeesList.csv`);
        
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        
        setAlertMessage("Report generated");
      } else {
        setAlertMessage("Credential Wrong.");
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  return (
    <div className="flex flex-row justify-center h-screen">
      <div className="flex flex-col items-center w-lg-card my-auto border border-deep-blue-600 border-solid py-lg-card" style={{ fontFamily: "Playfair Display" }}>
        <div className="flex flex-col mb-sm-card lg:mb-lg-card">
          <div className="flex flex-row items-center">
            <h1 className="text-deep-blue-600 text-sm-h4-article leading-6">Username</h1>
          </div>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="border-b border-solid border-deep-blue-600 h-10 w-full outline-none bg-transparent"
          />
        </div>
        <div className="flex flex-col mb-sm-card lg:mb-lg-card">
          <div className="flex flex-row items-center">
            <h1 className="text-deep-blue-600 text-sm-h4-article leading-6">Password</h1>
          </div>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="border-b border-solid border-deep-blue-600 h-10 w-full outline-none bg-transparent"
          />
        </div>
        <div className={`h-10 font-bold leading-loose cursor-pointer rounded-custom outline-none bg-deep-blue-600 w-fit-content mt-lg-card mx-auto lg:mx-0`} >
          <button onClick={() => generateReport()}>
            <div className="flex flex-row justify-center w-40 h-full items-center">
              <h2 className="text-sm-h3-card tracking-wider text-white leading-4" style={{ fontFamily: "Francois One" }}>
                Generate Report
              </h2>
            </div>
          </button>
        </div>
        {
          alertMessage && (
            <h2 className="text-sm-h3-card tracking-wider text-red-700 leading-4 mt-sm-card" style={{ fontFamily: "Francois One" }}>
              { alertMessage }
            </h2>
          )
        }
      </div>
    </div>
  );
};

export default GenerateReportPage;

